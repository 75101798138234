import React, { useState, useEffect } from 'react';
import { Search, X, ChevronDown } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL || 'https://wapperendehandjes.nl:444';

const SearchApp = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fieldValues, setFieldValues] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({
    AUTEUR: '',
    JAAR: '',
    TYPE: '',
    CATEGORIE: '',
    TAAL: '',
    LOCATIE: ''
  });
  const [openFilter, setOpenFilter] = useState(null);

  // Fields to show in results (keeping your original fields)
  const displayFields = ['TITEL', 'AUTEUR', 'UITGEVER', 'TREFWOORD', 'JAAR', 'TYPE', 'CATEGORIE', 'BESTAND', 'NUMMER', 'LOCATIE', 'TEKSTBLAD'];
  
  // Filter configuration with Dutch labels (using your original filter fields)
  const filterConfig = [
    { field: 'AUTEUR', label: 'Auteur' },
    { field: 'JAAR', label: 'Jaar' },
    { field: 'TYPE', label: 'Type' },
    { field: 'CATEGORIE', label: 'Categorie' },
    { field: 'TAAL', label: 'Taal' },
    { field: 'LOCATIE', label: 'Locatie' }
  ];

  useEffect(() => {
    // Fetch available values for each filter
    filterConfig.forEach(({ field }) => {
      fetch(`${API_URL}/api/fields/${field}/values`)
        .then(res => res.json())
        .then(data => {
          setFieldValues(prev => ({
            ...prev,
            [field]: data
          }));
        })
        .catch(error => console.error(`Error fetching ${field} values:`, error));
    });
  }, []);

  const handleSearch = async () => {
    if (!query && !Object.values(selectedFilters).some(Boolean)) return;

    setLoading(true);
    try {
      // Start with base URL
      let searchUrl = `${API_URL}/api/search`;
      let params = [];
      
      // Add query if exists
      if (query) {
        params.push(`q=${encodeURIComponent(query)}`);
      }

      // Add filters without encoding spaces as +
      Object.entries(selectedFilters).forEach(([field, value]) => {
        if (value) {
          // Replace URLSearchParams encoding with manual encoding that preserves spaces
          const encodedValue = value.replace(/[^\w\s-]/g, c => 
            '%' + c.charCodeAt(0).toString(16).padStart(2, '0'));
          params.push(`${field}=${encodedValue}`);
        }
      });

      // Combine URL with params
      searchUrl = `${searchUrl}?${params.join('&')}`;
      console.log('Search URL:', searchUrl); // For debugging
      
      const response = await fetch(searchUrl);
      const data = await response.json();
      setResults(data.results);
    } catch (error) {
      console.error('Search error:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle filter selection
  const handleFilterChange = (field, value) => {
    setSelectedFilters(prev => ({
      ...prev,
      [field]: value
    }));
    setOpenFilter(null); // Close dropdown after selection
  };

  // Clear specific filter
  const clearFilter = (field) => {
    setSelectedFilters(prev => ({
      ...prev,
      [field]: ''
    }));
  };

  // Clear all filters
  const clearAllFilters = () => {
    setSelectedFilters({
      AUTEUR: '',
      JAAR: '',
      TYPE: '',
      CATEGORIE: '',
      TAAL: '',
      LOCATIE: ''
    });
  };

  // Close filter dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openFilter && !event.target.closest('.filter-dropdown')) {
        setOpenFilter(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [openFilter]);

  // Trigger search when filters or query changes
  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearch();
    }, 300); // Debounce search

    return () => clearTimeout(timer);
  }, [selectedFilters, query]);

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold text-gray-800 mb-8">VHW Archief Zoeken</h1>
        
        {/* Search input */}
        <div className="mb-8">
          <div className="relative">
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Zoek in het archief..."
              className="w-full p-4 pr-12 rounded-lg border border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <Search className="absolute right-4 top-4 text-gray-400" />
          </div>
        </div>

        {/* Filter section */}
        <div className="mb-8">
          <div className="flex flex-wrap gap-4 mb-4">
            {filterConfig.map(({ field, label }) => (
              <div key={field} className="relative filter-dropdown">
                <button
                  onClick={() => setOpenFilter(openFilter === field ? null : field)}
                  className={`flex items-center justify-between min-w-[200px] px-4 py-2 rounded-lg border ${
                    selectedFilters[field] ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
                  } focus:outline-none focus:ring-2 focus:ring-blue-500`}
                >
                  <span className="mr-2">
                    {selectedFilters[field] ? `${label}: ${selectedFilters[field]}` : `Filter op ${label}`}
                  </span>
                  <ChevronDown size={20} className={`transform transition-transform ${openFilter === field ? 'rotate-180' : ''}`} />
                </button>

                {openFilter === field && (
                  <div className="absolute z-10 w-full mt-2 bg-white border border-gray-200 rounded-lg shadow-lg max-h-60 overflow-y-auto">
                    {selectedFilters[field] && (
                      <button
                        onClick={() => clearFilter(field)}
                        className="w-full px-4 py-2 text-left text-red-600 hover:bg-red-50 border-b border-gray-200"
                      >
                        Filter wissen
                      </button>
                    )}
                    {fieldValues[field]?.map(value => (
                      <button
                        key={value}
                        onClick={() => handleFilterChange(field, value)}
                        className={`w-full px-4 py-2 text-left hover:bg-gray-100 ${
                          selectedFilters[field] === value ? 'bg-blue-50 text-blue-600' : ''
                        }`}
                      >
                        {value}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ))}

            {/* Clear all filters button */}
            {Object.values(selectedFilters).some(Boolean) && (
              <button
                onClick={clearAllFilters}
                className="px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg border border-red-300"
              >
                Alle filters wissen
              </button>
            )}
          </div>
        </div>

        {/* Results section */}
        <div className="space-y-6">
          {loading ? (
            <div className="text-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mx-auto"></div>
              <div className="mt-4 text-gray-600">Zoeken...</div>
            </div>
          ) : (
            <>
              {results.length > 0 ? (
                results.map(({ document, score }, index) => (
                  <div
                    key={index}
                    className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow"
                  >
                    {displayFields.map(field => (
                      document[field] && (
                        <div key={field} className="mb-2">
                          <span className="font-semibold text-gray-600">{field}: </span>
                          <span className="text-gray-800">{document[field]}</span>
                        </div>
                      )
                    ))}
                    {document.BIJLAGE && (
                      <div className="mt-4">
                        <span className="text-sm text-gray-500">
                          Bijlage: {document.BIJLAGE}
                        </span>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="text-center py-8 text-gray-500">
                  Geen resultaten gevonden {query ? `voor "${query}"` : ''}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchApp;